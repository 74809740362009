import { AfterViewInit, Directive, ElementRef, Input, inject } from '@angular/core';
import { SwiperOptions } from 'swiper/types';

@Directive({
    selector: '[swiperElement]',
    standalone: true,
})
export class SwiperDirective implements AfterViewInit {
    private el = inject<ElementRef<HTMLElement>>(ElementRef);

    swiperElement: HTMLElement;

    @Input('config')
    config?: SwiperOptions;

    constructor() {
        const el = this.el;

        this.swiperElement = el.nativeElement;
    }

    ngAfterViewInit() {
        Object.assign(this.el.nativeElement, this.config);
        console.log('SWIPER-CONFIG', this.config);
        setTimeout(() => {
            // @ts-ignore
            this.el.nativeElement.initialize();
        });
    }
}
